<template>
  <b-card-code title="User's Management List">
    <b-link to="/users/add">
      <b-button
        v-show="isAdmin"
        variant="outline-primary"
        class="bg-gradient-primary mt-2 side-button"
        type="submit"
      >
        <span class="align-middle">Add New User</span>
      </b-button>
    </b-link>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="dataRows"
      :is-loading="loading"
      style-class="vgt-table striped"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <div
        slot="emptystate"
        align="center"
      >
        No Data
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Status -->
        <span
          v-if="props.column.field === 'Status'"
          v-b-modal.modal-center
          :title="props.row.Komentar"
        >
          <b-badge :variant="statusVariant(props.row.Status)">
            {{ props.row.Status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'Action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none nopad"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="detailUser(props.row.id)">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>View Detail</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-show="isAdmin"
                @click="validateData({id: props.row.id, name: props.row.Name})"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span>Remove</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ totalPage }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="totalPage"
              :per-page="totalRow"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="modal-detete"
      ref="modaldelete"
      v-model="modalDelete"
      centered
      title="Remove User"
      cancel-title="Cancel"
      ok-title="Remove"
      @ok="remove"
    >
      <span> Remove User <b>{{ selectedItem.name }} </b></span>
    </b-modal>
  </b-card-code>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BLink,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from '@axios'
import dayjs from 'dayjs'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    VBModal,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      pageTitle: '',
      loading: true,
      pageLength: 10,
      dir: false,
      isAdmin: false,
      modalDelete: false,
      totalPage: 0,
      totalRow: 0,
      page: 0,
      fromPage: 0,
      toPage: 0,
      selectedItem: {},
      // codeBasic,
      columns: [
        {
          label: 'Name',
          field: 'Name',
        },
        {
          label: 'NIK',
          field: 'NIK',
        },
        {
          label: 'Education',
          field: 'Education',
        },
        {
          label: 'Join Date',
          field: 'JoinDate',
        },
        {
          label: 'Status',
          field: 'Status',
        },
        {
          label: 'Action',
          field: 'Action',
        },
      ],
      dataRows: [
        {
          NIK: '',
          Name: '',
          Education: '',
          JoinDate: '',
          authorize: false,
          Status: '',
          Action: '',
          Komentar: [],
        },
      ],
      searchTerm: '',
      Status: [
        {
          1: 'Procces',
          2: 'Proses',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Procces: 'light-primary',
        Active: 'light-success',
        Inactive: 'light-danger',
        Resigned: 'light-warning',
        Process: 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  watch: {
    $route() {
      this.getDisposisi()
    },
    pageLength() {
      this.getDisposisi()
    },
  },
  created() {
    if (store.state.userData.user.role === '1') {
      this.isAdmin = true
    } else {
      this.isAdmin = false
    }
  },
  mounted() {
    this.getDisposisi()
  },
  methods: {
    detailUser(e) {
      window.location.href = `users/detail?id=${e}`
    },
    validateData(val) {
      this.modalDelete = !this.modalDelete
      this.selectedItem = val
    },
    async getDisposisi(val) {
      const { data } = await axios.get('/user', {
        params: {
          // page: this.page === 0 ? null : this.page,
        },
      })
      this.totalPage = data.data.length
      this.totalRow = this.pageLength
      this.fromPage = data.from
      this.toPage = data.to
      if (data.total !== 0) {
        this.dataRows = data.data.map(e => ({
          id: e.id || null,
          NIK: e.nik || '-',
          Name: e.nama || '-',
          Education: e.pendidikan || '-',
          JoinDate: dayjs(e.tanggal_gabung).format('DD-MM-YYYY') || '-',
          Status: e.status === 'ACTIVE' ? 'Active' : 'Inactive',
          Action: '',
        }))
      } else {
        this.dataRows = []
      }
      this.loading = false
      //   .catch(error => {
      //     console.log(error)
      //   })
    },
    async remove() {
      await axios
        .delete(`user/${this.selectedItem.id}`)
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDisposisi()
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
.nopad {
  padding: 0;
}
.link-no {
  border-bottom: solid 1px #c5c5c5;
  padding-bottom: 3px;
  cursor: pointer;
}
</style>
